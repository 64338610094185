.root-sections {
    margin-top: 16px;
}

.root-sections-list {
    display: flex;
    gap: 8px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 16px 16px 16px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.root-sections-list::-webkit-scrollbar {
    display: none;
}

.root-sections-item {
    display: flex;
    flex-direction: column;
    flex: 0 0 100px;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
    box-shadow: 1px 3px 3px 0 rgba(236,74,155,0.0902);
    border-radius: 4px;
    text-decoration: unset;
    padding: 5px 0;
}

.root-sections-item:hover {
    border-color: transparent;
    outline: none;
}

.root-sections-item span {
    font-weight: normal;
    font-size: 12px;
    color: #333;
    padding: 0 5px;
    text-align: center;
    line-height: 14px;
    display: inline-block;
}

.root-sections-item.brands span {
    font-size: 16px;
    line-height: 16px;
}

.root-sections-btn-wrap {
    padding: 0 20px;
    margin-bottom: 20px;
}

.root-sections-btn {
    border: none;
    width: 100%;
    height: 56px;
    background: #ec4a9b;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.root-sections-btn span {
    color: #fff;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (min-width: 1024px) {
    .root-sections {
        display: none;
    }
}

@media (max-width: 374px) {
    .root-sections-btn-wrap {
        padding: 0 10px;
    }
}