.main-section {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 1599px) {
    .main-section {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 1249px) {
    .main-section {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media only screen and (max-width: 767px) {
    .main-section {
        margin: 0 auto 10px;
    }
}

.main-section__sidebar {
    max-width: 420px;
    width: 100%;
    -ms-flex-preferred-size: 420px;
    flex-basis: 420px;
    min-width: 0;
}

@media only screen and (max-width: 1249px) {
    .main-section__sidebar {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.main-section__content {
    max-width: 475px;
    width: 100%;
    position: absolute;
    left: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media only screen and (max-width: 767px) {
    .main-section__content {
        left: 4px;
    }
}

.main-section__info-box {
    max-width: 335px;
    width: 100%;
    background: #ec4a9b;
    padding: 18px 30px 22px;
}

@media only screen and (max-width: 767px) {
    .main-section__info-box {
        max-width: 215px;
        width: 100%;
        background: #ec4a9b;
        padding: 8px 10px 8px;
    }
}

.main-section__subtitle {
    font-size: 22px;

    color: #fff;
}

@media only screen and (max-width: 767px) {
    .main-section__subtitle {
        font-size: 12px;
    }
}

.main-section__title {
    font-size: 46px;

    color: #fff;
}

@media only screen and (max-width: 767px) {
    .main-section__title {
        font-size: 22px;
    }
}

.main-section__discount {
    font-size: 96px;

    color: #fff;
    margin-left: 140px;
    margin-bottom: -22px;
    z-index: 2;
}

@media only screen and (max-width: 767px) {
    .main-section__discount {
        font-size: 46px;
    }
}

.main-section__price-box {
    background: #fff;
    max-width: 292px;
    width: 100%;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: auto;
}

@media only screen and (max-width: 767px) {
    .main-section__price-box {
        max-width: 168px;
        height: 30px;
        margin-left: 0;
    }
}

.main-section__price-new {
    font-size: 24px;

    color: #db3940;
}

@media only screen and (max-width: 767px) {
    .main-section__price-new {
        font-size: 12px;
    }
}

.main-section__price-old {
    font-size: 16px;
    text-decoration: line-through;
    color: #333;
    margin-left: 20px;
}

@media only screen and (max-width: 767px) {
    .main-section__price-old {
        font-size: 10px;
    }
}

.main-section__product-item {
    display: block;
    position: relative;
    text-decoration: none;
    margin-bottom: 20px;
    overflow: hidden;
}

@media only screen and (max-width: 1249px) {
    .main-section__product-item {
        margin: 0 5px 0;
    }

    .main-section__product-item:first-of-type {
        margin-left: 0;
    }

    .main-section__product-item:last-of-type {
        margin-right: 0;
    }
}

.main-section__product-item img {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    min-height: 237px;
}

.main-section__product-item .product-label {
    position: absolute;
    left: -60px;
    top: -60px;
    width: 120px;
    height: 120px;
    color: #fff;
    text-align: center;
    line-height: 190px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    z-index: 2;
}

.main-section__product-item .product-label--new {
    text-transform: uppercase;
    background: #96d052;
}

.main-section__product-item .product-label--sale {
    text-transform: uppercase;
    background: #e74d99;
}

.main-section__product-item .product-label--top {
    text-transform: uppercase;
    background: #9175c9;
}

.main-section__product-item .product-label--hot {
    background: #f3cc55;
}

.main-section__product-item:last-of-type {
    margin-bottom: 0;
}

@media only screen and (min-width: 1025px) {
    .main-section__product-item:hover img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);

    }
}

.main-section__product-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    padding: 12px 17px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.main-section__product-title {
    max-width: 225px;
    width: 100%;
    max-height: 36px;
    overflow: hidden;
    font-size: 16px;
    color: #fff;
}

.main-section__product-price {
    text-align: right;
}

.main-section__product-price .new-price {
    font-size: 18px;

    color: #ec4a9b;
}

.main-section__product-price .old-price {
    font-size: 16px;
    color: #a5aaae;
    text-decoration: line-through;
}

.main-section__product-price .current-price {
    font-size: 18px;
    color: #a4aeb4;
}

.main-section__product-price .current-price small {
    font-size: 16px;
}


@media only screen and (max-width: 1249px) {
    .main-section__sidebar {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        max-width: 1040px;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .main-section__sidebar {
        display: none;
    }
}

@media only screen and (max-width: 1249px) {
    .main-section__product-list {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

/*.main-section__lg-img {
    height: 100%;
}*/

.main-service {
    position: relative;
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 95px;
}

@media only screen and (max-width: 1599px) {
    .main-service {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 1023px) {
    .main-service {
        display: none;
    }
}

.main-service__main-title {
    font-size: 18px;

    color: #53595d;
    text-transform: uppercase;
}

.main-service__inner {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.main-service__inner::before {
    content: '';
    border: 1px solid #d1d5d8;
    position: absolute;
    top: 41px;
    left: 25px;
    bottom: 25px;
    right: 0;
}

.main-service__slider-wrap {
    background-color: #f0f3f4;
    background-position: right;
    background-repeat: no-repeat;
    margin-right: 25px;
}

.main-service__slider {
    -webkit-transform: translateY(-98px);
    transform: translateY(-98px);
    max-width: 1250px;
    margin: 0 auto;
}

.main-service__slider .slick-list {
    padding: 27px 0 0;
}

.main-service__slide {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}

.main-service__image {
    position: relative;
    max-width: 430px;
    width: 100%;
    -ms-flex-preferred-size: 430px;
    flex-basis: 430px;
    margin-right: 85px;
    min-width: 0;
}

.main-service__decore-image {
    position: absolute;
    right: -57px;
    top: -27px;
}

.main-service__content {
    max-width: 580px;
    width: 100%;
    -ms-flex-preferred-size: 580px;
    flex-basis: 580px;
    min-width: 0;
}

.main-service__title {
    font-size: 32px;

    color: #333;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.main-service__text {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #333;
    line-height: 1.5;
    margin-bottom: 20px;
}

.main-service__link {
    max-width: 152px;
    width: 100%;
    height: 40px;
    background: #333;
    font-size: 14px;

    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .main-service__link:hover {
        background: #ec4a9b;
    }
}

.main-service__controls {
    max-width: 866px;
    width: 100%;
    height: 42px;
    margin-left: auto;
    margin-bottom: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 1450px) {
    .main-service__controls {
        max-width: 695px;
    }
}

@media only screen and (max-width: 1249px) {
    .main-service__controls {
        max-width: 535px;
    }
}

.main-service__navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.main-service__counter {
    margin-right: 34px;
}

.main-service__counter .slide-current {
    font-size: 32px;

    color: #333;
}

.main-service__counter .slide-all {
    font-size: 18px;

    color: #333;
}

.main-service__arrows {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.main-service__arrows .nav-arrow {
    width: 42px;
    height: 42px;
    border: 1px solid #d1d5d8;
}

.main-service__arrows .nav-arrow .slider__prev-btn,
.main-service__arrows .nav-arrow .slider__next-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.main-service__arrows .nav-arrow .slider__prev-btn svg,
.main-service__arrows .nav-arrow .slider__next-btn svg {
    width: 7px;
    height: 9px;
    fill: #a4aeb4;
}

.main-service__prev {
    margin-right: -1px;
}

.main-service__decore-title {
    position: absolute;
    right: 5%;
    bottom: 10%;
    font-size: 62px;

    color: #fff;
    text-transform: uppercase;
    text-shadow: 0px 10px 20px rgba(47, 74, 90, 0.14);
}

.main-service__decore-subtitle {
    font-size: 18px;

    color: #53595d;
    text-transform: uppercase;
    position: absolute;
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    bottom: 20%;
    left: 5%;
}

.promotional-block {
    position: relative;
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 75px;
}

@media only screen and (max-width: 1599px) {
    .promotional-block {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .promotional-block {
        display: none;
    }
}

.promotional-block::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

.promotional-block__content {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 25px;
}

.promotional-block__subtitle {
    font-size: 26px;
    color: #fff;
    text-align: center;
}

@media only screen and (max-width: 1023px) {
    .promotional-block__subtitle {
        font-size: 16px;
    }
}

.promotional-block__title {
    font-size: 60px;

    color: #fff;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1023px) {
    .promotional-block__title {
        font-size: 50px;
        margin-bottom: 10px;
    }
}

.promotional-block__title::before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: rgba(255, 255, 255, 0.4);
}

.promotional-block__title::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: rgba(255, 255, 255, 0.4);
}

.promotional-block__title span {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 0 42px;
}

.promotional-block__price-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.promotional-block__price-new {
    font-size: 26px;

    color: #ec4a9b;
    margin: 0 17px;
}

@media only screen and (max-width: 1023px) {
    .promotional-block__price-new {
        font-size: 22px;
    }
}

.promotional-block__price-old {
    font-size: 26px;
    color: #fff;
    text-decoration: line-through;
    margin: 0 17px;
}

@media only screen and (max-width: 1023px) {
    .promotional-block__price-old {
        font-size: 22px;
    }
}

.main-catalog {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 90px;
}

@media only screen and (max-width: 1599px) {
    .main-catalog {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .main-catalog {
        margin: 0 auto 60px;
    }
}

.main-catalog__list {
    margin: 0 -9px 0 0;
}

@media only screen and (max-width: 1599px) {
    .main-catalog__list {
        margin: 0;
    }
}

.main-catalog__list .slick-dots {
    bottom: -50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 22px;
}

@media only screen and (max-width: 767px) {
    .main-catalog__list .slick-dots {
        bottom: -35px;
    }
}

.main-catalog__list .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.main-catalog__list .slick-dots li:first-of-type {
    margin-left: 0;
}

.main-catalog__list .slick-dots li:last-of-type {
    margin-right: 0;
}

.main-catalog__list .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #ccc;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.main-catalog__list .slick-dots li button::before {
    display: none;
}

.main-catalog__list .slick-dots li.slick-active button {
    border-color: #ec4a9b;
    background: #ec4a9b;
}

.main-catalog__list .main-catalog__btn {
    top: 50%;
}

@media only screen and (max-width: 1450px) {
    .main-catalog__list .slick-slide {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 1023px) {
    .main-catalog__list .slick-slide {
        margin: 0 5px;
    }
}

.main-catalog__item {
    position: relative;
    max-width: 238px;
    -ms-flex-preferred-size: 238px;
    flex-basis: 238px;
    width: 100%;
    display: block;
    margin: 0 5px;
    min-height: 358px;
    -webkit-box-shadow: inset 0px 23px 136px 0px rgba(210, 213, 215, 0.6);
    box-shadow: inset 0px 23px 136px 0px rgba(210, 213, 215, 0.6);
}

.main-catalog__item:first-of-type {
    margin-left: 0;
}

.main-catalog__item:last-of-type {
    margin-right: 0;
}

@media only screen and (max-width: 1023px) {
    .main-catalog__item {
        min-height: 260px;
    }
}

@media only screen and (max-width: 479px) {
    .main-catalog__item {
        min-height: 192px;
    }
}

@media only screen and (min-width: 1025px) {
    .main-catalog__item:hover .main-catalog__item-image img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);

    }

    .main-catalog__item:hover .main-catalog__item-title {
        background: #ec4a9b;
    }
}

.main-catalog__item-image {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
}

.main-catalog__item-image img {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;

}

.main-catalog__item-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #333;
    font-size: 12px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    height: 54px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 42px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (max-width: 1023px) {
    .main-catalog__item-title {
        padding: 0 10px;
    }
}

.main-catalog__btn {
    position: absolute;
    top: 32%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 3;
    cursor: pointer;
}

.main-catalog__btn svg {
    fill: #b2c0c9;
    width: 15px;
    height: 30px;
}

@media only screen and (max-width: 1650px) {
    .main-catalog__btn {
        z-index: 2;
        border: 1px solid #b2c0c9;
        background: #fff;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.main-catalog__prev-btn {
    left: -50px;
}

@media only screen and (max-width: 1650px) {
    .main-catalog__prev-btn {
        left: 5px;
    }
}

.main-catalog__next-btn {
    right: -50px;
}

@media only screen and (max-width: 1650px) {
    .main-catalog__next-btn {
        right: 5px;
    }
}

.main-products {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 100px;
    min-height: 665px;
}

.main-products .main-link {
    margin-top: 50px;
}

@media only screen and (max-width: 1599px) {
    .main-products {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .main-products {
        margin: 0 auto 40px;
    }
}

.main-products swiper-container {

}

.main-products swiper-container::part(button-prev) {
    right: 35px;
    left: auto;
}

.main-products swiper-container::part(button-next) {
    right: 0;
}

.main-products swiper-container::part(button-prev),
.main-products swiper-container::part(button-next) {
    bottom: auto;
    top: 25px;
    height: 25px;
    color: black;
}

.main-products__nav-list {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
    border: 1px solid #e1e6e9;
}

.main-products__nav-item {
    position: relative;
    font-size: 14px;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex !important;
    text-transform: uppercase;
}

@media only screen and (max-width: 1249px) {
    .main-products__nav-item {
        font-size: 12px;
    }
}

.main-products__nav-item::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 10px;
    width: 1px;
    background: #cecece;
}

.main-products__nav-item a {
    padding: 0 50px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #333;
    text-decoration: none;
}

@media only screen and (max-width: 1249px) {
    .main-products__nav-item a {
        padding: 0 20px;
    }
}

.main-products__nav-item a::before {
    content: '';
    width: 0%;
    height: 2px;
    background: #b0bfc8;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.main-products__nav-item a::after {
    content: '';
    width: 0%;
    height: 2px;
    background: #b0bfc8;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.main-products__nav-item a.active {
    color: #333;

}

.main-products__nav-item a.active::before {
    width: 100%;
}

.main-products__nav-item a.active::after {
    width: 100%;
}

.main-products__btn {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 22px;
    height: 100%;
    background: #b0bfc8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 3;
    cursor: pointer;
}

.main-products__btn svg {
    fill: #fff;
    width: 5px;
    height: 11px;
}

.main-products__btn.slick-disabled {
    opacity: 0.3;
}

.main-products__prev-btn {
    left: 0;
}

.main-products__next-btn {
    right: 0;
}

.main-products__slider.slick-dotted.slick-slider {
    margin-bottom: 70px;
}

.main-products__slider .product__item {
    max-width: 356px;
    width: 100%;
    -ms-flex-preferred-size: 356px;
    flex-basis: 356px;
}

.main-products__slider .product__image {
    height: 355px;
    width: 355px;
    cursor: pointer;
    max-width: 100%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .main-products__slider .product__image a > img {
        max-width: 260px;

    }
}

@media only screen and (-ms-high-contrast: active) and (max-width: 767px) {
    .main-products__slider .product__image a > img {
        width: auto;
        max-width: 100%;

    }
}

@media only screen and (max-width: 1249px) {
    .main-products__slider .product__image {
        height: 300px;
    }
}

@media only screen and (max-width: 767px) {
    .main-products__slider .product__image {
        height: 272px;
    }
}

.main-products__slider .product__info-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 26px;
}

@media only screen and (max-width: 1249px) {
    .main-products__slider .product__info-top {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-bottom: 15px;
    }
}

.main-products__slider .product__title {
    max-width: 240px;
    width: 100%;
    margin-right: 15px;
}

@media only screen and (max-width: 1249px) {
    .main-products__slider .product__title {
        margin: 0 0 10px;
        max-width: 280px;
    }
}

.main-products__slider .product__info-list {
    display: none;
}

.main-products__slider .product__buttons .buy-btn {
    margin-right: 15px;
}

.main-products__slider .product__buttons .compare-btn,
.main-products__slider .product__buttons .wishlist-btn {
    margin: 0 12px;
}

.main-products__slider .slick-dots {
    bottom: -40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 22px;
}

.main-products__slider .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.main-products__slider .slick-dots li:first-of-type {
    margin-left: 0;
}

.main-products__slider .slick-dots li:last-of-type {
    margin-right: 0;
}

.main-products__slider .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #ccc;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.main-products__slider .slick-dots li button::before {
    display: none;
}

.main-products__slider .slick-dots li.slick-active button {
    border-color: #ec4a9b;
    background: #ec4a9b;
}

.main-products .swiper-slide {
    min-width: 190px !important;
}