.promo-slider {
    width: 100%;
}

.promo-slider .swiper-slide {
    margin-right: 20px;
    width: 232px;
    height: 470px;
}

.promo-slider .swiper-slide-double {
    width: 484px !important;
}